import * as React from 'react'
import { Display, Content, Columns, SepV, Rows, Panel } from '../design-system'
import { LayoutCard } from '../twt-core/molecules'
import { TWTCorePage } from '../twt-core/organisms'

const NotFoundPage = () => (
  <TWTCorePage>
    <LayoutCard flex={1} lineOffsetY={-4} lineCount={3} lines>
      <Rows alignItems="center" justifyContent="center">
        <Panel padding={5}>
          <Columns padding={2} spacing={2} alignItems="center">
            <Display>404</Display>
            <SepV />
            <Content>Try something else</Content>
          </Columns>
        </Panel>
      </Rows>
    </LayoutCard>
  </TWTCorePage>
)

export default NotFoundPage
